<!---->
<template>
  <list-template
      :search-config="searchConfig"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :current-page="page"
      @onSearch="onSearch"
      @onReset="search=null"
      @onChangePage="changeCurrentPage"
      @onHandle="tableHandle"
  ></list-template>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data(){
    return {
      searchConfig:[
        {
          placeholder:"搜索班主任姓名",
          prop:"teacher_name",
        },
        {
          tag:"select",
          placeholder:"是否完成工作",
          prop:"complete_status",
          options:[
            {
              value:0,
              label:"全部"
            },
            {
              value:1,
              label:"已完成"
            },
            {
              value:2,
              label:"未完成"
            }
          ]
        },
        {
          tag:"datePicker",
          property:{
            placeholder:"筛选日期",
            type:"daterange",
            startPlaceholder:"开始日期",
            endPlaceholder:"结束日期",
            size:"small",
            style:{marginRight:'20rem'},
            valueFormat:"yyyy-MM-dd"
          },
          prop:"date",
        },
      ],
      tableConfig:[
        {
          prop:"teacher",
          label:"班主任姓名",
          width:"120rem",
        },
        {
          prop:"whole_status",
          label:"工作完成情况",
          width:"120rem",
          render(row){

            return `<span ${row['whole_status']===0?'style="color:red"':''}>${row["whole_status"]!==0?'已完成工作':"未完成工作"}</span>`
          }
        },
        {
          prop:"class",
          label:"行政班",
          width:"150rem",

        },
        {
          prop:"work_date",
          label:"日期",
          width:"114rem",
        },
        {
          prop:"early_reading",
          label:"查早操",
          width:"80",
        },
        {
          prop:"check_health",
          label:"查卫生",
          width:"80",
        },
        {
          prop:"self_study",
          label:"查晚自习",
          width:"100rem",

        },
        {
          prop:"study_habits",
          label:"学习习惯监督",
          width:"120rem",
        },
        {
          prop:"work_reflection",
          label:"工作反思",
          width:"100rem",
        },
        {
          prop:"school",
          label:"校区",
        },
        {
          prop:"handle",
          label:"详情",
          width:"80rem",
          handle:true,
          property:{
            fixed:"right"
          },
          buttons: [{ type:"view", text:"查看" }]
        },
      ],
      search:null,
      total:0,
      tableData:[],
    }
  },
  computed:{
    ...mapGetters(["userInfo"]),
    ...mapState(["page"])
  },
  methods:{

    tableHandle(row,text,index){
      this.$router.push({path:"./show",query:{id:row.id,type:row.whole_status}});
    },
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit("setPage",e);
      this.getData();
    },
    // 获取数据
    getData(){
      let {search,page} = this;
      let params = {page}
      if (search){
        if (search.date){
          params = {
            teacher_name:search.teacher_name,
            complete_status:search.complete_status,
            date_start:search.date[0],
            date_end:search.date[1],
            page
          }
        }else{
          params = {
            ...params,
            ...search
          }
        }
      }
      // 数据请求
      this.$_axios2.get("/api/work/teacher-class/journal",{
        params
      }).then(res=>{
        let {data} = res;
        if (data.status === 0){
          this.tableData = data.data.list;
          this.total = data.data.page.total;
        }else{
          this.$message.error(data.msg);
        }
      }).catch(err=>{
      })
    },
    // 搜索功能
    onSearch(val){
      this.$store.commit("setPage",1);
      this.search = val;
      this.getData();
    }
  },
  mounted() {
    this.$store.commit("setPage",1);
    this.getData();
  },
  activated() {
    this.getData();
  },
}
</script>

<style scoped>

</style>
